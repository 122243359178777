<template>
  <div>
    <div class="form">
      <h1>Управление секторами</h1>

      <div class="form--body">
        <div class="block-6">
          <div class="form--field">
            <label>Название сектора</label><br />
            <input v-model="form.label" />
          </div>
        </div>

        <div class="block-6">
          <div class="form--field">
            <label>Список привязанных операций</label>
            <div class="wrp-operation">
              <div class="select-area-item" v-for="(operation, index) in form.operations"
                   :key="index">
                <a class="list-minus" @click="del_operation_list(index)" href="javascript:void(0)">
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </a>
                <span>{{ operation.label }}</span>
              </div>
            </div>
            <div class="select-and-btn">
              <a class="btn-def btn-plus" href="javascript:void(0)" @click="add_operation_list">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </a>
              <select v-model="selSelect" >
                <option value="-1" disabled>Выберите операцию для привязки</option>
                <option v-for="(operation, index) in listOperations" :key="index"
                        :value="index" >{{operation.label}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <a class="btn-def" href="javascript:void(0)"
         @click="add_custom_operation">Добавить сектор</a>
    </div>

    <div class="alist">
      <div class="alist--head">Список секторов</div>
      <template v-if="listSector.length">
        <div class="alist--item" v-for="(sector, index) in listSector" :key="index">
          <a class="alist--del" href="javascript:void(0)"
             @click="del_custom_operation(sector.id, index)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
          <span>{{ sector.name }}</span>
        </div>
      </template>
      <template v-else>
        <div class="mess-info">Нет секторов</div>
      </template>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'InterfaceSector',
  data() {
    return {
      listSector: [],
      listOperations: [],
      form: {
        label: '',
        operations: [],
      },
      selSelect: -1,
      errors: [],
    };
  },
  created() {
    this.get_free_operation();
    this.get_custom_operation();
  },
  methods: {
    get_free_operation() {
      axios.get('api/list-free-operations-for-sector')
        .then((res) => {
          this.listOperations = res.data.list;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    get_custom_operation() {
      axios.get('api/list-sector')
        .then((res) => {
          this.listSector = res.data.list;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    add_operation_list() {
      this.form.operations.push(this.listOperations[this.selSelect]);
      this.listOperations.splice(this.selSelect, 1);
      this.selSelect = -1;
    },
    del_operation_list(index) {
      this.listOperations.push(this.form.operations[index]);
      this.form.operations.splice(index, 1);
    },
    add_custom_operation() {
      axios.post('api/add-sector', { form: this.form })
        .then((res) => {
          if (res.data.success === 1) {
            this.listSector.push(res.data.elem);
            this.form = {
              label: '',
              operations: [],
            };
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    del_custom_operation(val, index) {
      axios.post('api/del-sector', { id: val })
        .then((res) => {
          if (res.data.success === 1) {
            this.listSector.splice(index, 1);

            this.get_free_operation();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style scoped>
.form--body{display:flex; margin-left:-12px; margin-right:-12px;}
.form--field{margin-bottom:10px; padding:0px 12px;}

.form--field input{width:100%}
.form--field select{width:100%; padding: 10px 8px;
  outline: none;
  border: 1px solid #b5b5b5;
  border-radius: 4px;}

.wrp-operation{
  background: #cac3ec;
  border: 1px dashed #8750ca;
  margin-bottom: 12px;
}

.btn-plus-list{width:49px}
.select-and-btn{}
.select-and-btn .btn-def{float:right; width: 11%}
.select-and-btn select{width:88%; height:46px;}

.block-6{flex:6}

.alist{margin-top:20px; margin-bottom:20px;}
.alist--head{background: #3e5977; color:#fff; padding: 6px 12px;}
.alist--item{background: #fff;
  padding: 5px 12px;
  border-bottom: 1px solid #c1c1c1; }
.alist--del{float: right;
  color: #f22b2b;
  font-size: 23px;
  line-height: 24px;}
.mess-info{background: #b1c8e6; padding: 6px 12px; color: #005dd7;}
.list-minus{float:right; color: #f22b2b}
.select-area-item{padding: 6px 12px}
</style>
